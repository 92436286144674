<template>
  <div v-if="contract" class="lg:page lg:flex">
    <SuccessCard v-if="sellMkt || cancelMkt" :closeable="false">
      <template #icon>
        <img
          v-if="sellMkt"
          src="@/assets/svg/email_grey_border.svg"
          alt="success "
          class="mx-auto"
        />
        <img
          v-else-if="cancelMkt"
          src="@/assets/svg/exclamation_grey_border.svg"
          alt="success"
          class="mx-auto"
        />
      </template>
      <template #title>
        <p
          v-if="sellMkt"
          class="text-2xl font-medium text-grey-2 my-6 text-left lg:text-center"
        >
          {{ $t('contracts.details.mkt_sale_card.title') }}
        </p>
        <p
          v-else-if="cancelMkt"
          class="text-2xl font-medium text-grey-2 my-6 text-left lg:text-center"
        >
          {{ $t('mkt.sell.cancel_confirmation_card_title') }}
        </p>
      </template>
      <template #text>
        <p
          v-if="sellMkt"
          class="text-sm text-grey-2 text-opacity-80 text-left lg:text-center"
          v-html="$t('contracts.details.mkt_sale_card.text')"
        />
        <p
          v-else-if="cancelMkt"
          class="text-sm text-grey-2 text-opacity-80 text-left lg:text-center"
          v-html="$t('mkt.sell.cancel_confirmation_card_text')"
        />
      </template>
      <template #button>
        <div v-if="sellMkt" class="mt-8 lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto lg:m-0"
            desktopWidth="w-full"
            @click="() => (sellMkt = false)"
          >
            {{ $t('wording.cancel') }}
          </GpButton>
          <router-link :to="'/mkt-sell/' + slug">
            <GpButton class="mx-auto lg:m-0" desktopWidth="w-full">
              {{ $t('wording.continue') }}
            </GpButton>
          </router-link>
        </div>
        <div v-else-if="cancelMkt" class="mt-8 lg:grid lg:grid-cols-2 lg:gap-5">
          <GpButton
            color="white"
            class="mb-4 mx-auto"
            desktopWidth="w-full"
            @click="() => (cancelMkt = false)"
          >
            {{ $t('wording.no') }}
          </GpButton>
          <GpButton class="mx-auto" @click="cancelOffer" desktopWidth="w-full">
            {{ $t('wording.yes') }}
          </GpButton>
        </div>
      </template>
    </SuccessCard>
    <div v-else class="lg:container lg:pt-20">
      <div class="hidden lg:flex pb-6">
        <Back :to="'/contracts'" title="" white />
      </div>

      <!-- Header -->
      <div
        class="pt-10 px-6 pb-12 lg:rounded-t-2xl relative"
        :class="{
          'bg-grey-600': !contract.images
        }"
      >
        <div
          class="
            absolute
            w-full
            h-full
            top-0
            left-0
            lg:rounded-t-2xl
            z-0
            bg-cover
          "
          :class="{
            'filter grayscale': contract.state === 'settled'
          }"
          :style="
            contract.images
              ? 'background-image: url(\'' + contract.images.path + '\')'
              : ''
          "
        >
          <div
            class="
              absolute
              w-full
              h-full
              top-0
              left-0
              grey-image-filter
              lg:rounded-t-2xl
            "
          />
        </div>
        <div class="lg:hidden relative">
          <Back to="/contracts" title="" white />
        </div>
        <div class="w-full flex lg:justify-end -mb-6 lg:mb-0 lg:-mt-4">
          <MktTags :label="contract.contract_label" />
        </div>
        <!-- Project name -->
        <p class="mt-8 text-left text-xl font-bold text-white mb-4 relative">
          {{ contract.project_name }}
        </p>
        <!-- Status -->
        <div class="flex text-white text-sm relative text-left">
          <img
            v-if="contract.contract_label === 'marketplace_sell'"
            src="@/assets/svg/contracts/mkt-white.svg"
            alt="done"
            class="mr-1"
          />
          <img
            v-else-if="contract.state === 'settled'"
            src="@/assets/svg/contracts/tick-white.svg"
            alt="done"
            class="mr-1"
          />
          <img
            v-else-if="contract.payment_in_arrears"
            src="@/assets/svg/contracts/clock-orange.svg"
            alt="time"
            class="mr-1"
          />
          <img
            v-else
            src="@/assets/svg/contracts/money-white.svg"
            alt="money"
            class="mr-1"
          />
          <p
            v-if="contract.contract_label === 'marketplace_sell'"
            class="font-semibold text-opacity-80"
          >
            {{
              $t('contracts.details.mkt_sold_status') +
              ' ' +
              dateFormatting(
                new Date(contract.marketplace.purchase_date),
                '/',
                true
              )
            }}
          </p>
          <p
            v-else-if="contract.state === 'settled'"
            class="font-semibold text-opacity-80"
          >
            {{ $t('contracts.details.settled') }}
          </p>
          <p v-else-if="contract.payment_in_arrears" class="text-orange-sun-4">
            <span>
              {{
                $t('contracts.arrears') +
                ': ' +'$' + 
                dollarFormatting(
                  contract.upcoming_payments.next_payment
                    .instalment_investor_net
                ) 
              }}
            </span>
            <span v-if="contract.upcoming_payments.next_payment" class="font-bold">
              {{
                $t('wording.since') +
                ' ' +
                dateFormatting(
                  new Date(
                    contract.upcoming_payments.next_payment.schedule_date
                  ),
                  '/',
                  true
                ).slice(0, 5)
              }}
            </span>
          </p>
          <p v-else-if="contract.upcoming_payments.days_to_next_payment === 0">
            {{
              $t('contracts.details.next_payment') +
              ' ' +'$' + 
              dollarFormatting(
                contract.upcoming_payments.next_payment.instalment_investor_net
              ) +
              $t('wording.today')
            }}
          </p>
          <p v-else>
            {{
              $t('contracts.details.next_payment') +
              ' ' +'$' + 
              dollarFormatting(
                contract.upcoming_payments.next_payment.instalment_investor_net
              ) + ' ' +
              $t('wording.in') +
              ' ' +
              $t(
                'wording.day',
                {
                  n: contract.upcoming_payments.days_to_next_payment
                },
                contract.upcoming_payments.days_to_next_payment
              )
            }}
          </p>
        </div>
      </div>
      <!-- Status bar -->
      <div
        class="
          px-6
          py-4
          bg-green-seaweed-1
          flex
          items-center
          lg:rounded-b-2xl lg:mb-12
        "
        :class="{
          'bg-blue-ocean-2':
            contract.contract_label === 'marketplace_sell' ||
            contract.state === 'settled',
          'bg-orange-sun-3': contract.payment_in_arrears
        }"
      >
        <div
          class="relative flex mr-4 items-center"
          style="height: 50px; width: 50px"
          v-if="contract.contract_label !== 'marketplace_sell'"
        >
          <div class="absolute top-0 left-0" style="height: 50px; width: 50px">
            <vue3-chart-js
              :id="chartData.id"
              :type="chartData.type"
              :data="chartData.data"
              :options="chartData.options"
              @before-render="() => {}"
            >
            </vue3-chart-js>
          </div>
          <div class="w-full flex-grow">
            <p
              class="font-bold text-xs"
              :class="
                contract.state === 'settled'
                  ? 'text-white'
                  : 'text-blue-ocean-2'
              "
            >
              {{ paidPercentage }}%
            </p>
          </div>
        </div>
        <p
          v-if="contract.contract_label !== 'marketplace_sell'"
          class="text-sm"
          :class="
            contract.state === 'settled' ? 'text-white' : 'text-blue-ocean-2'
          "
        >
          <span class="font-semibold">
            {{
              $t('contracts.details.received') +
              ' ' +'$' + 
              dollarFormatting(
                contract.sums.capital_paid + contract.sums.interest_gross_paid
              ) 
            }}
          </span>
          <span
            >{{
              ' ' + $t('wording.of') +
              ' ' +'$' + 
              dollarFormatting(
                contract.capital_invested +
                  contract.sums.interest_gross_paid +
                  contract.sums.interest_gross_due
              ) 
            }}
          </span>
        </p>
        <p v-else class="text-white text-sm text-center w-full">
          {{
            $t('contracts.details.mkt_sold_status_bar') +
            ' ' +'$' + 
            dollarFormatting(contract.marketplace.offer_value) 
          }}
        </p>
      </div>
      <!-- Details -->
      <div class="page px-6 lg:px-0">
        <!-- Principal) -->
        <div class="bg-white p-6 rounded-2xl mb-4">
          <!-- Title (invested) -->
          <div
            class="
              flex
              items-center
              justify-between
              text-grey-2 text-sm
              font-semibold
              cursor-pointer
              mb-2
            "
          >
            <div class="flex">
              <p class="text-left">{{ $t('contracts.details.capital_invested') }}</p>
            </div>
            <p>${{ dollarFormatting(contract.capital_invested) }}</p>
          </div>
          <!-- Paid back -->
          <div
            class="flex items-center justify-between text-grey-2 text-sm mb-2"
          >
            <p class="text-left">{{ $t('contracts.details.capital_paid') }}</p>
            <p>${{ dollarFormatting(contract.sums.capital_paid) }}</p>
          </div>
          <!-- Outstanding -->
          <div
            v-if="contract.contract_label !== 'marketplace_sell'"
            class="flex items-center justify-between text-grey-2 text-sm"
          >
            <p class="text-left">{{ $t('contracts.details.capital_outstanding') }}</p>
            <p>
              ${{
                dollarFormatting(
                  contract.upcoming_payments.next_payment
                    ? contract.upcoming_payments.next_payment
                        .capital_outstanding
                    : 0
                )
              }}
            </p>
          </div>
          <div v-else>
            <div
              class="flex items-center justify-between text-grey-2 text-sm"
            >
              <p>{{ $t('contracts.details.mkt_offer_value') }}</p>
              <p>${{ dollarFormatting(contract.marketplace.offer_value) }}€</p>
            </div>
            <div
              class="flex items-center justify-between text-grey-2 text-sm"
            >
              <p>{{ $t('contracts.details.mkt_offer_fee') }}</p>
              <p>${{ dollarFormatting(contract.marketplace.offer_fee) }}</p>
            </div>
          </div>
        </div>
        <!-- Interest -->
        <div class="bg-white p-6 rounded-2xl">
          <!-- Title (total interest) -->
          <div
            class="
              flex
              items-center
              justify-between
              text-grey-2 text-sm
              mb-2
              font-bold
            "
          >
            <p v-if="contract.state !== 'settled'">
              {{ $t('contracts.details.interest_total') }}
            </p>
            <p v-else>
              {{ $t('contracts.details.interest_received') }}
            </p>
            <p>
              ${{
                dollarFormatting(
                  contract.sums.interest_gross_due +
                    contract.sums.interest_gross_paid
                )
              }}
            </p>
          </div>
          <!-- Interest received -->
          <div
            v-if="contract.state !== 'settled'"
            class="flex items-center justify-between text-grey-2 text-sm mb-2"
          >
            <p class="text-left">
              {{ $t('contracts.details.interest_received') }}
            </p>
            <p>${{ dollarFormatting(contract.sums.interest_gross_paid)}}</p>
          </div>
          <!-- Interest due -->
          <div
            class="flex items-center justify-between text-grey-2 text-sm mb-2"
          >
            <p class="text-left">
              {{ $t('contracts.details.interest_due') }}
            </p>
            <p>${{ dollarFormatting(contract.sums.interest_gross_due) }}</p>
          </div>
        </div>
        <div class="lg:bg-white lg:bg-opacity-60 lg:p-6 lg:rounded-b-2xl">
          <!-- Payment plan -->
          <!-- <div
        v-if="payments"
        class="w-full flex items-center justify-between mt-12 mb-4"
      >
        <p v-if="contract.state === 'settled'" class="text-left text-grey-2">
          {{ $t('contracts.details.payment_plan.title_settled') }}
        </p>
        <p v-else class="text-left text-grey-2">
          {{ $t('contracts.details.payment_plan.title') }}
        </p>
        <Link
          :link="'/contract-details/' + slug + '/payment-plan'"
          :text="$t('contracts.details.full_plan')"
          textStyle="text-sm font-bold text-orange-1 mr-1"
          router
        >
          <template #singleImage>
            <img
              src="@/assets/svg/arrows/left_orange_arrow.svg"
              alt="right"
              class="transform rotate-180"
            />
          </template>
        </Link>
        <PaymentPlan
          v-if="payments"
          :payments="payments"
          :contract="contract"
        /> -->

          <!-- Dates and total payments -->
          <!-- Sold on (mkt) -->
          <div
            class="
              flex
              items-center
              justify-between
              text-grey-3 text-sm
              font-semibold
            "
            v-if="contract.contract_label === 'marketplace_sell'"
          >
            <p>{{ $t('contracts.details.mkt_sold_on') }}</p>
            <p>
              {{
                dateFormatting(
                  new Date(
                    contract.marketplace.purchase_date
                  ),
                  '/',
                  true
                )
              }}
            </p>
          </div>
          <div v-else>
            <!-- Total payments -->
            <div
              v-if="contract.state !== 'settled'"
              class="
                flex
                items-center
                justify-between
                text-grey-3 text-sm
                mb-4
                mt-4
                font-semibold
              "
            >
              <p>
                {{ $t('contracts.details.upcoming_payments') }}
              </p>
              <p>
                {{ contract.upcoming_payments.upcoming_payments_count }}
              </p>
            </div>
            <!-- Start date -->
            <div
              v-else
              class="
                flex
                items-center
                justify-between
                text-grey-3 text-sm
                mb-4
                font-semibold
              "
            >
              <p>{{ $t('contracts.details.start_date') }}</p>
              <p>
                {{ dateFormatting(new Date(contract.start_date), '/', true) }}
              </p>
            </div>
            <!-- End date -->
            <div
              v-if="contract.state !== 'settled'"
              class="
                flex
                items-center
                justify-between
                text-grey-3 text-sm
                font-semibold
              "
            >
              <p>{{ $t('contracts.details.end_date') }}</p>
              <p>
                {{ dateFormatting(new Date(contract.end_date), '/', true) }}
              </p>
            </div>
          </div>
        </div>
        <!-- Cancel offer -->
        <div
          v-if="contract.contract_label === 'for_sale'"
          class="p-4 bg-orange-5 rounded-2xl mt-6"
        >
          <p class="text-left text-sm font-semibold text-grey-3 mb-1">
            {{
              $t('contracts.details.mkt_selling') +
              ' ' +
              dateFormatting(
                new Date(contract.marketplace.creation_date),
                '/',
                true
              ) +
              '.'
            }}
          </p>
        </div>
        <!-- <div class="lg:grid lg:grid-cols-2 lg:gap-4 mt-8">
          <GpButton
            v-if="contract.after_contract_id"
            color="white"
            desktopWidth="w-full"
            @click="downloadContractPdf(contract.after_contract_id)"
          >
            {{ $t('contracts.details.transfer_agreement_download') }}
          </GpButton>
          <GpButton
            color="white"
            desktopWidth="w-full"
            @click="downloadContractPdf(contract.id)"
          >
            {{ $t('contracts.details.contract_download') }}
          </GpButton>
        </div> -->
        <!-- Payment plan -->
        <div
          v-for="(value, key) in contract.links.extra_files"
          :key="value"
          class="
            bg-white
            rounded-2xl
            mt-4
            flex
            items-center
            justify-between
            px-6
            py-4
          "
        >
          <p class="text-sm font-bold text-grey-2 text-left">
            {{ key.split('.')[0] }}
          </p>
          <Link
            :link="value"
            :text="$t('wording.download')"
            textStyle="text-sm font-semibold text-orange-1 ml-1"
            imageFirst
          >
            <template #singleImage>
              <img
                src="@/assets/svg/arrows/down_orange_arrow.svg"
                alt="right"
              />
            </template>
          </Link>
        </div>
        <PaymentPlan
          v-if="payments"
          :payments="payments"
          :contract="contract"
        />

        <!-- Project details -->
        <div
          class="
            w-full
            flex
            items-center
            justify-between
            cursor-pointer
            bg-white
            rounded-2xl
            p-4
            mt-12
            transition
            ease-in-out
            duration-200
            transform
            hover:shadow-light hover:-translate-y-1
          "
          @click="goToProject"
        >
          <p class="text-sm font-semibold text-grey-3">
            {{ $t('contracts.details.campaign_details') }}
          </p>
          <img src="@/assets/svg/arrows/right_grey_arrow.svg" alt="arrow" />
        </div>

        <!-- NPC download -->
        <div
          class="
            w-full
            flex
            items-center
            justify-between
            cursor-pointer
            bg-white
            rounded-2xl
            p-4
            mt-8
          "
          @click="downloadNPC"
        >
          <p class="text-sm font-semibold text-grey-3">
            {{ $t('settings.documents.note_purchase_confirmation') }}
          </p>
          <img src="@/assets/svg/transactions/download.svg" alt="arrow" />
        </div>

        <!-- Impact -->
        <p
          v-if="cards.length"
          class="text-left text-grey-2 mt-12 mb-4"
        >
          {{ $t('contracts.details.impact_title') }}
        </p>
        <ImpactCarousel v-if="cards.length" :cards="cards" />
        <!-- Sell on mkt -->
        <!-- <GpButton
          v-if="
            contract.state !== 'settled' &&
            contract.contract_label !== 'for_sale' &&
            !contract.payment_in_arrears
          "
          color="white"
          class="ml-auto mt-16"
          @click="() => (sellMkt = true)"
        >
          {{ $t('contracts.details.mkt_sell_btn') }}
        </GpButton> -->
        <!-- Cancel offer -->
        <GpButton
          v-if="contract.contract_label === 'for_sale'"
          color="white"
          class="ml-auto mt-16"
          @click="() => (cancelMkt = true)"
        >
          {{ $t('mkt.sell.cancel_offer') }}
        </GpButton>
      </div>
    </div>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import Back from '@/components/general/Back.vue'
import GpButton from '@/components/general/GpButton.vue'
import SuccessCard from '@/components/general/SuccessCard.vue'
import MktTags from '@/components/contracts/MktTags.vue'
// import TimelineBox from "@/components/projects/TimelineBox.vue";
import ImpactCarousel from '@/components/impact/ImpactCarousel'
import Link from '@/components/general/Link.vue'
import { useI18n } from 'vue-i18n'
import PaymentPlan from '@/components/contracts/PaymentPlan.vue'
import axios from 'axios'
import { useStore } from 'vuex'
import { computed, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'

export default {
  components: {
    Vue3ChartJs,
    Back,
    MktTags,
    GpButton,
    Link,
    PaymentPlan,
    // TimelineBox,
    SuccessCard,
    ImpactCarousel
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { dollarFormatting, dateFormatting, slugIt } = useGlobalHelpers()
    const sellMkt = ref(false)
    const cancelMkt = ref(false)
    const i18n = useI18n({ useScope: 'global' })

    const contract = computed(() => {
      return store.state.contracts.contract
    })

    const payments = computed(() => {
      return store.getters.getPaymentsPreview()
    })

    const paidPercentage = computed(() => {
      if (contract.value) {
        if (contract.value.state === 'settled') return 100
        return Math.round(
          ((contract.value.sums.capital_paid +
            contract.value.sums.interest_gross_paid) /
            (contract.value.capital_invested +
              contract.value.sums.interest_gross_paid +
              contract.value.sums.interest_gross_due)) *
            100
        )}
      return null
    })

    onBeforeMount(async () => {
      await store.dispatch('storeContract', null)
      await store.dispatch('fetchContract', route.params.id.split('-').pop())
    })

    function downloadNPC() {
      store.dispatch('setLoading', true)
      axios
        .get(process.env.VUE_APP_SERVER_BASE_URL  + 'api/v1/documents/open', {
          params: {
            template: 'note_purchase_agreement',
            investment: contract.value.id
          },
          responseType: 'arraybuffer'
        })
        .then((response) => {
          store.dispatch('setLoading', false)
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'goparity_download.pdf'
          link.click()
        }).catch((e) => {
          store.dispatch('setLoading', false)
          store.dispatch('setError', e)
        })
    }

    const downloadContractPdf = (contract_id) => {
      axios
        .get(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/contracts/' +
            parseInt(contract_id) +
            '/files',
          {
            params: { file_type: 'contract_pdf' },
            responseType: 'arraybuffer'
          }
        )
        .then((response) => {
          let blob = new Blob([response.data], {
            type: 'application/pdf'
          })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          let name =
            contract.value.after_contract_id === contract_id
              ? '_Transfer_Agreement'
              : '_Loan_Agreement'
          link.download = contract.value.project_name + name + '.pdf'
          link.click()
        })
    }

    const chartData = computed(() => {
      return {
        id: 'doughnut',
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [paidPercentage.value, 100 - paidPercentage.value],
              backgroundColor: ['#ffffff', '#ffffff80'],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '85%',
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          }
        }
      }
    })

    const goToProject = () => {
      const slug = contract.value.project_name
        ? slugIt(contract.value.project_name) + '-' + contract.value.project_id
        : contract.value.project_id
      router.push('/projects/' + slug)
    }

    const slug = computed(() => {
      return contract.value.project_name
        ? slugIt(contract.value.project_name) + '-' + contract.value.id
        : contract.value.id
    })

    const cancelOffer = () => {
      axios
        .delete(
          process.env.VUE_APP_SERVER_BASE_URL +
            'api/v1/offers/' +
            contract.value.marketplace.id
        )
        .then(async () => {
          await store.dispatch(
            'fetchContract',
            route.params.id.split('-').pop()
          )
          cancelMkt.value = false
          const popup = {
            title: i18n.t('mkt.sell.cancel_popup.title'),
            align: 'center',
            side: false,
            iconBg: 'bg-green-5',
            icon: '/img/done_step.svg',
            content: i18n.t('mkt.sell.cancel_popup.text'),
            primaryButton: i18n.t('mkt.sell.cancel_popup.btn')
          }
          store.dispatch('setPopup', popup)
        }).catch((e) => {
          store.dispatch('setLoading', false);
          store.dispatch('setError', e)
      })
    }

    const cards = computed(() => {
      let finalCards = []
      if (Math.round(contract.value.impact_metrics.kwh_clean_energy / 1000) > 0)
        finalCards.push({
          key: 'clean_energy',
          title: i18n.t('impact.impact.clean_energy.title'),
          longTitle: i18n.t('impact.impact.clean_energy.long_title'),
          description: i18n.t('impact.impact.clean_energy.description'),
          value: contract.value.impact_metrics.kwh_clean_energy / 1000,
          rank: 21,
          suffix: 'MWh',
          totalRank: 5768,
          bg: 'blue-night',
          dark: true
        })
      if (Math.round(contract.value.impact_metrics.people_impacted) > 0)
        finalCards.push({
          key: 'people',
          title: i18n.t('impact.impact.people.title'),
          longTitle: i18n.t('impact.impact.people.long_title'),
          description: i18n.t('impact.impact.people.description'),
          value: contract.value.impact_metrics.people_impacted,
          rank: 21,
          totalRank: 5768,
          bg: 'green-mint',
          dark: false
        })
      if (Math.round(contract.value.impact_metrics.jobs_generated) > 0)
        finalCards.push({
          key: 'jobs',
          title: i18n.t(
            'impact.impact.jobs.title',
            contract.value.impact_metrics.jobs_generated == 1 ? 1 : 0
          ),
          longTitle: i18n.t(
            'impact.impact.jobs.long_title',
            contract.value.impact_metrics.jobs_generated == 1 ? 1 : 0
          ),
          description: i18n.t('impact.impact.jobs.description'),
          value: contract.value.impact_metrics.jobs_generated,
          rank: 21,
          totalRank: 5768,
          bg: 'green-lime',
          dark: false
        })
      if (Math.round(contract.value.impact_metrics.ha_impacted) > 0)
        finalCards.push({
          key: 'sustainable_land',
          title: i18n.t('impact.impact.sustainable_land.title'),
          longTitle: i18n.t('impact.impact.sustainable_land.long_title'),
          description: i18n.t('impact.impact.sustainable_land.description'),
          value: contract.value.impact_metrics.ha_impacted,
          suffix: 'ha',
          rank: 21,
          totalRank: 5768,
          bg: 'orange-3',
          dark: true
        })
      return finalCards
    })

    return {
      contract,
      dollarFormatting,
      dateFormatting,
      payments,
      downloadContractPdf,
      downloadNPC,
      chartData,
      paidPercentage,
      goToProject,
      slug,
      sellMkt,
      cancelMkt,
      cancelOffer,
      cards
    }
  }
}
</script>
